import React from 'react'

import { CheckoutContext } from './checkoutContext'

export const useCheckout = () => {
  const context = React.useContext(CheckoutContext)

  if (!context) {
    throw new Error('The useCheckout should within CheckoutProvider')
  }

  return context
}
