import React from 'react'

import { UpsellContext } from './UpsellProvider'

export const useUpsell = () => {
  const context = React.useContext(UpsellContext)

  if (!context) {
    throw new Error('useUpsellProvider must be used within a UpsellProvider')
  }

  return context
}
