import React from 'react'

import { CalculatorContext } from './CalculatorProvider'

export const useCalculator = () => {
  const context = React.useContext(CalculatorContext)

  if (!context) {
    throw new Error('The useCalculator should within CalculatorProvider')
  }

  return context
}
