import React from 'react'

import { ConfigContext } from './changeableDueDateContext'

export const useChangeableDueDate = () => {
  const context = React.useContext(ConfigContext)

  if (!context) {
    throw new Error(
      'The useChangeableDueDate should within ChangeableDueDateProvider',
    )
  }

  return context
}
