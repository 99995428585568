import React from 'react'

import { useRemoteConfig } from '@azos/core'

export interface UpsellContextValue {
  isLoading: boolean
  specialistUpsell: { name: string; imageUrl: string }
  section: number
  setSection: (value: number) => void
}

export const UpsellContext = React.createContext<UpsellContextValue>(
  {} as UpsellContextValue,
)

export const UpsellProvider: React.FCC = ({ children }) => {
  const remoteConfig = useRemoteConfig()
  const specialistUpsell: { name: string; imageUrl: string } = JSON.parse(
    remoteConfig.get<string>('UPSELL_SPECIALIST_NAME'),
  )

  const [section, setSection] = React.useState<number>(1)

  const value = {
    isLoading: !remoteConfig,
    specialistUpsell,
    section,
    setSection,
  }

  return (
    <UpsellContext.Provider value={value}>{children}</UpsellContext.Provider>
  )
}
