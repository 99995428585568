import React from 'react'

import { useRemoteConfig } from '@azos/core'

export interface DueDateContextValue {
  isLoading: boolean
  isChangeableDueDateActive: boolean
}

export const ConfigContext = React.createContext<DueDateContextValue>(
  {} as DueDateContextValue,
)

export const ChangeableDueDateProvider: React.FCC = ({ children }) => {
  const remoteConfig = useRemoteConfig()
  const isChangeableDueDateActive = remoteConfig.get<boolean>(
    'WEB_B2C_SET_DUE_DATE_CONTRACT',
  )

  return (
    <ConfigContext.Provider
      value={{
        isLoading: !remoteConfig,
        isChangeableDueDateActive,
      }}
    >
      {children}
    </ConfigContext.Provider>
  )
}
