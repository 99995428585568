import React from 'react'

import { useStep } from '@Azos-Seguros/apollo'
import { makeStorageProvider } from '@azos/core'

export type SimulationFormData = {
  height?: string | number | undefined
  weight?: string | number | undefined
  is_smoker?: boolean | null | undefined
  profession?: Partial<{
    id: string
    description: string
    requiresDescription: boolean
    name: string
  }>
  salary?: number | null
}

export const SimulationContext = React.createContext<{
  formData: SimulationFormData
  setFormData: (params: SimulationFormData) => void
  useStep: [number, any]
}>({
  formData: {},
  setFormData: () => {},
  useStep: [0, {}],
})

type SimulationProviderProps = {
  children: React.ReactNode
  steps: number
}

export const SimulationProvider: React.FC<SimulationProviderProps> = ({
  children,
  steps,
}) => {
  const storageProvider = makeStorageProvider()
  const simulationCookie = '@azos/upsellSimulation'

  const simulationStorage = React.useMemo(
    () => storageProvider.getJSON<SimulationFormData>(simulationCookie),
    [storageProvider],
  )

  const [formData, setFormData] =
    React.useState<SimulationFormData>(simulationStorage)

  const handleSetFormData = (vl: SimulationFormData) => {
    const storageProvider = makeStorageProvider()
    storageProvider.set(simulationCookie, vl)
    setFormData(vl)
  }
  return (
    <SimulationContext.Provider
      value={{
        useStep: useStep(steps),
        formData: formData,
        setFormData: handleSetFormData,
      }}
    >
      {children}
    </SimulationContext.Provider>
  )
}
